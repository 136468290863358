import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CELL_GROUPS_CATEGORY_KEY } from 'components/cell-visualizations/Compare/useDataCategory'
import {
  CELL_IMAGE_NORMAL_SIZE,
  CELL_IMAGE_NORMAL_SPACING,
} from 'components/cell-visualizations/shared'
import { CellInfo, DifferentialFeaturesTopFeatureCount } from 'components/cell-visualizations/tsv/types'
import _ from 'lodash'
import { SessionPostData, TaskStatus } from 'utils/api'
import { getSimpleReducerMethods } from 'utils/reduxHelpers'
import { DatasetItem } from '../types/DatasetItem'
import {
  CellDataField,
  CellImagesFilter,
  CellInfoDimension,
  CellInfoGroups,
  DifferentialFeaturesComparison,
  MergedMorphotype,
  PaginationParams,
  PinnedMorphotype,
  TopFeature,
  ZoomThreshold,
} from './types'

export const imageCountDefault = 10
export const defaultPagination = { page: 0, cellsPerPage: imageCountDefault }

export interface CellsByGroup {
  groupId: number
  groupName: string
  cellIds: string[]
}

export enum FullscreenPanelType { // Used by the CellSelectionDrawer component in the CellVisualizationState
  NONE = 'none',
  DIFFERENTIAL_FEATURES = 'differential_features',
}

export enum MorphotypePanelDisplayMode {
  CELL_GROUPS = 'cell_groups',
  MERGE = 'merge',
  DIFFERENTIAL_FEATURES = 'differential_features',
}

// Keep this in sync with the initial state that's the next variable below
//
// Also if you add any temporary variables that should not be saved, please update
// saveVersionConfig in useSessionApi.ts
export interface CellVisualizationsState {
  /**
   * Indicates the version of this state. Used for matching stateVersion to logic in the backend
   * @important Whenever CellVisualizationsState is changed, please increment stateVersion
   */
  // Keep this in sync with the saveVersionConfig function in useSessionApi.ts
  // That function omits specific fields from this state that should not be persisted when saving
  stateVersion: 1

  // Overview information about this session
  // Name and project code are entered by the user when they save
  // fileName is the name of the file that was loaded
  fileName?: string
  name?: string
  projectCode?: string
  plotImgSrc?: string // holds screenshot of the plot for the session

  /**
   * Unfiltered, unedited arrow file data. Don't use this data if your object needs data that could be
   * hidden, removed, or filtered. Use the cellInfo from usePlotData instead
   */
  cellsData?: CellInfo[]

  // Name of the classifier, if the user trained a classifier using this version of the session
  classifierName?: string

  // Flags used to manage state within the applicaion
  dataRevision: number
  isDirty: boolean

  // Pre-filtering state
  morphotypesToRemove?: PinnedMorphotype[]

  // Color-by / Filter-by UX state (left panel)
  showColorBy?: boolean
  cellInfoGroups: CellInfoGroups
  selectedCellInfoGroupName: keyof CellInfoGroups

  // Display images settings and images to show at different zoom levels
  cellImagesFilter: CellImagesFilter
  zoomThresholds?: ZoomThreshold[]

  // Plot display type
  plotDisplayType: 'scatter' | 'density'

  // CellSelectionDrawer (right side panels) state management
  showMorphotypes?: boolean
  showCompare?: boolean
  fullscreenPanel?: FullscreenPanelType
  morphotypePanelDisplayMode?: MorphotypePanelDisplayMode

  // Morphotypes user has pinned (part of right panel)
  pinnedCells?: PinnedMorphotype[]
  mergedPinnedCells?: MergedMorphotype[] // holds merged morphotypes, if the user's merged

  // Morphotypes panel, differential features component UX state.
  // Stores the results list from comparisons
  differentialFeaturesComparisons?: DifferentialFeaturesComparison[]
  selectedDifferentialFeaturesTaskId?: number

  // Morphotypes panel, cells selected for image export
  exportCellMorphotype: CellsByGroup[]

  // Compare tab state
  groupCompare: {
    selectedFeatures: CellDataField[]
    selectedDataCategory: string // Datafield.label value
    selectedDataFields: { [key: string]: string[] } // key is a DataField.label value,
  }
  selectedComparisonDimensions?: CellInfoDimension[]

  // Selected demo dataset UX state
  selectedDataset?: DatasetItem
  differentialFeaturesTopFeatureCountMap ?:DifferentialFeaturesTopFeatureCount

  // Session data, without the version_config which is stored elsewhere in this slice
  sessionData?:SessionPostData
}
// Keep the CellvIsualizationSlice above in sync with the initial state that's below
//
// Also if you add any temporary variables that should not be saved, please update
// saveVersionConfig in useSessionApi.ts


// Keep this in sync with the type definition just above this
export const cellVisualizationsInitialState: CellVisualizationsState = {
  stateVersion: 1,

  // Overview information about this session
  // Name and project code are entered by the user when they save
  // fileName is the name of the file that was loaded
  fileName: undefined,
  name: undefined,
  projectCode: undefined,

  /**
   * Unfiltered, unedited arrow file data. Don't use this data if your object needs data that could be
   * hidden, removed, or filtered. Use the cellInfo from usePlotData instead
   */
  cellsData: undefined,

  // Flags used to manage state within the application
  dataRevision: 1,
  isDirty: false,

  // Pre-filtering state
  morphotypesToRemove: [],

  // Color-by / Filter-by UX state
  showColorBy: undefined,
  cellInfoGroups: {},
  selectedCellInfoGroupName: '',

  // Display Images settings
  cellImagesFilter: {
    displayImages: false,
    imageSize: CELL_IMAGE_NORMAL_SIZE,
    spacingAdjust: CELL_IMAGE_NORMAL_SPACING,
  },
  zoomThresholds: undefined,

  // Plot display type
  plotDisplayType: 'scatter',

  // CellSelectionDrawer (right side panels) state management
  showMorphotypes: undefined,
  showCompare: undefined,
  fullscreenPanel: FullscreenPanelType.NONE,
  morphotypePanelDisplayMode: MorphotypePanelDisplayMode.CELL_GROUPS,

  // Morphotypes user has pinned (part of right panel)
  pinnedCells: [],
  mergedPinnedCells: undefined,

  // Morphotypes panel, differential features component UX state
  differentialFeaturesComparisons: [],
  selectedDifferentialFeaturesTaskId: undefined, // if in full screen mode, this is the taskId to show in detail

  // Morphotypes panel, cells selected for image export
  exportCellMorphotype: [],

  // Compare tab state
  groupCompare: {
    selectedFeatures: [],
    selectedDataCategory: CELL_GROUPS_CATEGORY_KEY,
    selectedDataFields: {},
  },
  selectedComparisonDimensions: undefined,

  // Selected demo dataset UX state
  selectedDataset: undefined,
  differentialFeaturesTopFeatureCountMap :{},
  sessionData:undefined
}

export const cellVisualizationsSlice = createSlice({
  name: 'cellVisualizations',
  initialState: cellVisualizationsInitialState,
  reducers: {
    ...getSimpleReducerMethods(cellVisualizationsInitialState),
    setMorphotypeToRemoveHighlight: (
      state,
      action: PayloadAction<{ id: number; shouldHighlight: boolean }>
    ) => {
      const { id, shouldHighlight } = action.payload
      const morphotype = state.morphotypesToRemove?.find((m) => m.id === id)
      if (morphotype) {
        morphotype.isHighlighted = shouldHighlight
      }
    },
    setGroupCompare(
      state,
      action: PayloadAction<{
        groupCompare: CellVisualizationsState['groupCompare']
        eventsManager: {
          sendCompareByEvent(
            dataCategory: string,
            selectedComparisionOptions: CellDataField[],
            dataFieldsToCompare: { [key: string]: string[] }
          ): void
        }
      }>
    ) {
      const { groupCompare, eventsManager } = action.payload
      state.groupCompare = groupCompare
      state.isDirty = true
      eventsManager.sendCompareByEvent(
        groupCompare.selectedDataCategory,
        groupCompare.selectedFeatures,
        groupCompare.selectedDataFields
      )
    },
    /**
     * Only add cell ids to the group that don't already exist, if already exists remove
     * @param state
     * @param action action.payload - Contains an object groupId, cellId and groupName
     */
    appendCellToExport: (
      state,
      action: PayloadAction<{ groupId: number; cellId: string; groupName: string }>
    ) => {
      const { groupId, cellId, groupName } = action.payload

      const groupIndex = state.exportCellMorphotype.findIndex((morphotype) => {
        return morphotype.groupId === groupId
      })

      // Group already exists
      if (groupIndex !== -1) {
        const currentGroup = state.exportCellMorphotype[groupIndex]
        // Check if the Cell Id exists in the group
        const cellIndex = currentGroup.cellIds.findIndex((cell) => {
          return cell === cellId
        })

        // Cell Id already exist, remove from list (uncheck)
        if (cellIndex !== -1) {
          if (currentGroup.cellIds.length <= 10) {
            currentGroup.cellIds.splice(cellIndex, 1)
          }
          // If no cells exists, remove the group
          if (currentGroup.cellIds.length === 0) {
            state.exportCellMorphotype.splice(groupIndex, 1)
          }
        } else if (currentGroup.cellIds.length < 10) {
          // Append the Cell Id to the group
          currentGroup.cellIds.push(cellId)
        }
      } else {
        // Create the group and append the Cell Id
        state.exportCellMorphotype.push({ groupId, groupName, cellIds: [cellId] })
      }
    },
    setGroupCompareSelectedGroups(state, action: PayloadAction<number[]>) {
      state.pinnedCells = state.pinnedCells?.map((x) => ({
        ...x,
        isInSelectedGroup: action.payload.includes(x.id),
      }))
      state.isDirty = true
    },
    incrementDataRevision(state) {
      state.dataRevision += 1
    },
    setCellImagesFilter: (
      state,
      action: PayloadAction<{
        cellImagesFilter: CellImagesFilter
        eventsManager: { sendDisplayImagesByEvent(_: CellImagesFilter): void }
      }>
    ) => {
      const { cellImagesFilter, eventsManager } = action.payload
      eventsManager.sendDisplayImagesByEvent(cellImagesFilter)
      state.cellImagesFilter = cellImagesFilter
    },
    /**
     * Replaces the current state with the given payload.
     * @param state Current state
     * @param action action.payload contains the new state to apply
     *    Any fields that are missing will be set to their default initial value
     */
    setStore(state, action: PayloadAction<Partial<CellVisualizationsState>>) {
      // Using "state = action.payload" throws an error so we resort to this
      const newStore = action.payload
      _.keys(state).forEach((k) => {
        const key = k as keyof typeof state
        const val = newStore[key] ?? cellVisualizationsInitialState[key]

        ;(state[key] as typeof val) = val
      })
      state.isDirty = false
    },
    /** Clears state.  Normally used when you leave the cell visualization session
     * to avoid keeping around stale state the next time you create a new session or
     * open a different session
     */
    clearStore(state) {
      _.keys(state).forEach((k) => {
        const key = k as keyof typeof state
        const val = cellVisualizationsInitialState[key]
        ;(state[key] as typeof val) = val
      })
    },

    setPinnedGroupValue: (
      state,
      action: PayloadAction<{
        pinnedGroupId: number
        key: keyof PinnedMorphotype
        value: boolean | string | PaginationParams
      }>
    ) => {
      const { pinnedGroupId, key, value } = action.payload
      state.pinnedCells = state.pinnedCells?.map((pinnedCell) =>
        pinnedCell.id === pinnedGroupId
          ? {
              ...pinnedCell,
              [key]: value,
            }
          : pinnedCell
      )
    },
    setCellsData(state, action: PayloadAction<CellInfo[] | undefined>) {
      state.isDirty = false
      state.cellsData = action.payload
    },
    setPinnedCells: (
      state,
      action: PayloadAction<{
        pinnedCells: PinnedMorphotype[]
        eventsManager: { sendPinnedCellsEvent(_: PinnedMorphotype): void }
      }>
    ) => {
      const { pinnedCells, eventsManager } = action.payload
      const recentlyAddedPin = pinnedCells.at(0)
      if (recentlyAddedPin) eventsManager.sendPinnedCellsEvent(recentlyAddedPin)
      state.pinnedCells = pinnedCells
      state.isDirty = true
    },
    setMergedPinnedCells: (
      state,
      action: PayloadAction<{
        pinnedCells: MergedMorphotype[]
        eventsManager: {
          sendMergedPinnedCellsEvent(_: Pick<PinnedMorphotype, 'id' | 'cells'>[]): void
        }
      }>
    ) => {
      const { pinnedCells, eventsManager } = action.payload
      eventsManager.sendMergedPinnedCellsEvent(pinnedCells)
      state.mergedPinnedCells = pinnedCells
      state.isDirty = true
    },
    addPinnedMorphotype: (state, action: PayloadAction<PinnedMorphotype>) => {
      state.pinnedCells = state.pinnedCells
        ? [...state.pinnedCells, action.payload]
        : [action.payload]
      state.isDirty = true
    },
    clearExportMorphotype: (state) => {
      state.exportCellMorphotype = []
    },
    deletePinnedGroup: (
      state,
      action: PayloadAction<{
        targetPinnedCellId: number
        eventsManager: { sendDeletePinEvent(_: string): void }
      }>
    ) => {
      const { targetPinnedCellId, eventsManager } = action.payload
      const deletedPin = state.pinnedCells?.filter((x) => x.id === targetPinnedCellId)
      if (deletedPin && deletedPin.length > 0) eventsManager.sendDeletePinEvent(deletedPin[0].name)
      else eventsManager.sendDeletePinEvent(`pinnedCellId ${targetPinnedCellId}`)
      state.pinnedCells = state.pinnedCells?.filter((x) => x.id !== targetPinnedCellId)
      state.isDirty = true
    },

    /** Adds a comparison to the top of the differential features comparison list */
    addDifferentialFeaturesComparison: (
      state,
      action: PayloadAction<DifferentialFeaturesComparison>
    ) => {
      if (state.differentialFeaturesComparisons === undefined) {
        state.differentialFeaturesComparisons = []
      }
      state.differentialFeaturesComparisons.unshift(action.payload)
    },

    /** Deletes a comparison in the differential features result list by taskId */
    deleteDifferentialFeaturesComparisonByTaskId: (
      state,
      action: PayloadAction<{
        taskId: number
      }>
    ) => {
      const { taskId } = action.payload
      state.differentialFeaturesComparisons = state.differentialFeaturesComparisons?.filter(
        (item) => item.taskId !== taskId
      )
    },

    /** Updates the results of a differential features comparison, by taskId */
    updateDifferentialFeaturesComparison: (
      state,
      action: PayloadAction<{
        taskId: number
        status: TaskStatus
        topFeatures?: TopFeature[]
      }>
    ) => {
      const { taskId, status, topFeatures } = action.payload
      state.differentialFeaturesComparisons = state.differentialFeaturesComparisons?.map((item) =>
        item.taskId === taskId ? { ...item, status, topFeatures } : item
      )
    },
    setDifferentialFeaturesTopFeatureCount: (state, action) => {
      const { taskId, count } = action.payload
      if (state.differentialFeaturesTopFeatureCountMap) {
        state.differentialFeaturesTopFeatureCountMap[taskId] = count
      }
    },
    setSessionData: (state, action: PayloadAction<SessionPostData>) => {
      state.sessionData = action.payload
    },
  },
})

export const CellVisualizationsActions = cellVisualizationsSlice.actions
export const CellVisualizationsReducer = cellVisualizationsSlice.reducer
