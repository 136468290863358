import useEventsManager from 'redux/slices/hooks/useEventsManager'

import useDeepcellError, { ReasonCode } from 'components/shared/useDeepcellError'
import useFlagCondition from 'components/shared/useFlagCondition'
import { notificationComponent } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { readOnlySessionIds } from 'redux/types'
import {
  DeleteSessionParams,
  GetSessionsParams,
  SessionPostData,
  createSessionFromFile,
  createSessionFromRuns,
  deleteSession,
  getSessions,
  saveSession,
} from 'utils/api'
import useScatterPlotScreenshot from './plot/useScatterPlotScreenshot'
import useCellVisualizationUrlParams from './useCellVisualizationUrlParams'

const validStatus = (code: number) => code >= 200 && code < 300

type SaveVersionConfigParams = {
  name?: string
  classifierName?: string
  projectCode?: string
  trainClassifier?: boolean
  isCopy?: boolean
}
export interface EXErrorWithMessage {
  data: {
    error: string
    reason: string
  }
}
const useSessionApiLogic = () => {
  const { showProgress, showSuccess, showError, clearNotification } = useNotificationSlice()
  const { showError: showDeepcellError } = useDeepcellError()
  const eventsManager = useEventsManager()
  const { sessionId, versionId, updateVersion,updateSessionAndVersion } = useCellVisualizationUrlParams()
  const { setIsDirty, cellVisualizations } = useCellVisualizationsSlice()

  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const demoEnabled = useFlagCondition('demoEnabled')

  const triggerPreFilter = preFilterEnabled && !demoEnabled

  const getScreenshot = useScatterPlotScreenshot()

  return {
    /**
     * Returns true if delete was successful
     */
    deleteSession: async (params: DeleteSessionParams & { name: string }): Promise<boolean> => {
      const { id, name } = params
      let output = ''
      showProgress(`Deleting ${name}...`)
      try {
        const { status } = await deleteSession({ id })
        if (validStatus(status)) {
          showSuccess(`${name} successfully deleted`)
          return true
        }
        throw new Error()
      } catch {
        output = `Error when attempting to delete "${name}"`
      }

      if (output) {
        showError(output)
      }

      return false
    },
    getSessions: async (params?: GetSessionsParams) => {
      let output = ''
      showProgress('Retrieving sessions...')
      try {
        const { data, status } = await getSessions(params)
        if (validStatus(status)) {
          clearNotification()
          return data.data
        }
        throw new Error()
      } catch {
        output = 'Error when attempting to retrieve sessions'
      }

      if (output) {
        showError(output)
      } else {
        clearNotification()
      }

      return []
    },
    /**
     * Sends the current cell visualization state (known as version_config in the backend)
     * where a new version_id is generated for this session and returned
     */
    saveVersionConfig: async (params?: SaveVersionConfigParams) => {
      /** @TODO Replace this with something more robust
       * This is a temporary solution to prevent users from saving over a demo dataset
       * Implemented as part of DVDF-364
       */
      const isSavePermitted = readOnlySessionIds.indexOf(String(sessionId)) === -1
      if (!isSavePermitted) {
        showError('Cannot save a demo dataset. Please contact demo team.')
        throw new Error('Cannot save a demo dataset')
      }

      const { projectCode, isCopy, trainClassifier, ...newState } = params ?? {}
      const { name } = newState

      let output = ''
      showProgress(`Saving ${cellVisualizations.fileName || ''}...`)
      try {
        if (versionId === undefined) {
          throw new Error('Missing versionId')
        }

        // Get the latest screenshot of the plot
        const plotImgSrc = await getScreenshot()

        setIsDirty(false)

        const result = await saveSession({
          state: {
            ...cellVisualizations,
            ...newState,
            projectCode,
            plotImgSrc,
            /*
            When saving a session, set fields to undefined that are
            - ...for the specific instance of the app (ex: zoomThresholds, dataRevision, isDirty)
            - ...already stored in the backend (ex: cellsData)
            - ...aren't needed in the backend (ex: cellidMap, cellInfoGroups)
            */
            zoomThresholds: undefined,
            cellsData: undefined,
            fileName: undefined,
            dataRevision: undefined,
            cellInfoGroups: undefined,
            isDirty: undefined,
            sessionData: undefined,
          },
          sessionId,
          versionId,
          isCopy,
          trainClassifier,
        })

        if (result?.version_id === undefined) {
          throw new Error('No data returned')
        }

        const newVersionId = parseInt(result.version_id, 10)
        updateVersion(newVersionId)
      } catch (ex) {
        // @TODO Handle error responses more cleanly and show the user a way to recover
        // Especially if there is a ConflictError (i.e. saving over a stale version)
        output = 'Error when attempting to save'
      }

      if (output) {
        showError(output)
      } else {
        showSuccess(`${name ?? ''} Saved Successfully`)
        eventsManager.sendSaveEvent(projectCode)
      }

      return output
    },

    uploadNewBlobToCloud: async (params: { dataFromBlob?: Blob; fileName?: string }) => {
      const { dataFromBlob, fileName } = params
      eventsManager.sendExportEvent(fileName)
      let uploadBlobError = ''

      showProgress(`Uploading ${fileName}...`)

      if (dataFromBlob && fileName) {
        try {
          const { data, status } = await createSessionFromFile(dataFromBlob, fileName)
          if (validStatus(status) && data?.session_id !== undefined) {
            // @TODO assuming version id is 1.  We should actually return it from the backend
            updateSessionAndVersion(data.session_id, 1, triggerPreFilter)
          } else {
            throw new Error()
          }
        } catch {
          uploadBlobError = 'Error when attempting to upload data'
        }
      }

      if (uploadBlobError) {
        showError(uploadBlobError)
      } else {
        showSuccess(`Uploaded ${fileName} successfully`)
      }
    },

    createSessionFromRuns: async (params: Omit<Parameters<typeof createSessionFromRuns>[0], 'dl_only_projection'>) => {
      showProgress('Creating session...')

      let error = ''
      let result: { data: SessionPostData; status: number } | undefined;
      try {
        const { data, status } = await createSessionFromRuns({
          dl_only_projection: true, // always use dl_only_projection
          // All other parameters are required to be passed in in params
          ...params,
        })
        result = { data, status };
        if(!error){
          showSuccess('Session created successfully')
        }
      } catch (ex) {
        const sessionCreationError = ex as EXErrorWithMessage
        if (sessionCreationError?.data?.reason) {
          showDeepcellError(sessionCreationError?.data?.reason as ReasonCode, {
            message: sessionCreationError?.data?.error,
            component: notificationComponent.Toaster,
            severity: 'info',
          })

        }
        const runIdNotFoundError = `${ex}`.match(
          /([\s\S]*?)Expected 1 datatable with run_id([\s\S]*?)found 0$/
        )
        const diffCellCountsError = `${ex}`.includes('have different cell counts by')
        const cancelledByUser = `${ex}`.includes('by user')
        if (runIdNotFoundError || diffCellCountsError) {
          error =
            'Run data is still uploading. Please try again in 5 minutes, and if this error occurs again, please contact support'
        } else if (cancelledByUser) {
          error = 'Session creation has been cancelled'
        } else {
          error = 'Error when attempting to create session'
        }
      }

      if (error) {
        showError(error)
        }
      return result
    },
  }
}

export const useSessionApi = (): ReturnType<typeof useSessionApiLogic> => useSessionApiLogic()

export default useSessionApi
